import React from 'react'
import { graphql } from 'gatsby'
import { Container } from 'theme-ui'
import Layout from '@solid-ui-layout/Layout'
import Seo from '@solid-ui-components/Seo'
import Divider from '@solid-ui-components/Divider'
import ContentText from '@solid-ui-components/ContentText'
import ModalWithTabs from '@solid-ui-blocks/Modal/Block01'
import ModalSimple from '@solid-ui-blocks/Modal/Block02'
import Header from '@solid-ui-blocks/Header/Block01'
import Content from '@solid-ui-blocks/Content/Block03'
import Footer from '@solid-ui-blocks/Footer/Block01'
import { normalizeBlockContentNodes } from '@blocks-helpers'
import styles from './_styles'
import theme from './_theme'

const Privacy = props => {
  const { allBlockContent } = props.data
  const content = normalizeBlockContentNodes(allBlockContent?.nodes)

  return (
    <Layout theme={theme} {...props}>
      <Seo title='Home' />
      {/* Modals */}
      <ModalWithTabs content={content['authentication']} reverse />
      <ModalWithTabs content={content['contact']} />
      <ModalSimple content={content['advertisement']} />
      {/* Blocks */}
      <Header content={content['header']} />
      <Divider space='5' />
      <div style={{ textAlign: 'center', paddingRight: '5rem', paddingLeft: '5rem' }}>
      <div>
    <h2>Zelten LLC Privacy Policy Notice</h2>

    <p><strong>Effective Date:</strong> 12/20/2023</p>

    <p>At Zelten LLC, we are committed to protecting the privacy and security of your personal information. This Privacy Policy Notice outlines the types of information we collect, how we use it, and the measures we take to safeguard your data when you engage with our multimedia design, marketing, and programming services.</p>

    <p><strong>Information We Collect:</strong></p>
    <p>Personal Information: When you engage with Zelten LLC, we may collect personal information such as your name, email address, phone number, and other relevant contact details.</p>
    <p>Usage Data: We may collect data on how you interact with our website, services, and communications.</p>

    <p><strong>How We Use Your Information:</strong></p>
    <p>Providing Services: We use your information to deliver our multimedia design, marketing, and programming services as requested.</p>
    <p>Communication: We may use your contact information to communicate with you about our services, updates, and other relevant information.</p>
    <p>Improving Services: Your feedback and usage data help us enhance and optimize our offerings.</p>

    <p><strong>Data Security:</strong></p>
    <p>We employ industry-standard security measures to protect your personal information from unauthorized access, disclosure, alteration, and destruction.</p>
    <p>Our website and communication channels use secure protocols (such as HTTPS) to encrypt data during transmission.</p>

    <p><strong>Sharing Information:</strong></p>
    <p>We do not sell, trade, or rent your personal information to third parties.</p>
    <p>We may share your information with trusted partners and service providers who assist us in delivering our services, provided they adhere to our privacy standards.</p>

    <p><strong>Your Choices:</strong></p>
    <p>You have the right to access, correct, or delete your personal information. To exercise these rights or inquire about your data, please contact us at <a href="mailto:info@zeltenstudio.com">info@zeltenstudio.com</a>.</p>

    <p><strong>Updates to Privacy Policy:</strong></p>
    <p>We may update this Privacy Policy Notice to reflect changes in our practices. Check our website for the latest version.</p>

    <p>By engaging with Zelten LLC, you acknowledge that you have read and understood this Privacy Policy Notice. If you have any questions or concerns, please do not hesitate to contact us at <a href="mailto:info@zeltenstudio.com">info@zeltenstudio.com</a>.</p>

    <p>Thank you for choosing Zelten LLC for your multimedia design, marketing, and programming needs.</p>


  </div>

        </div>
      <Divider space='5' />
      <Footer content={content['footer']} />
    </Layout>
  )
}

export const query = graphql`
  query homepagePrivacyBlockContent {
    allBlockContent(
      filter: { page: { in: ["site/services", "shared"] } }
    ) {
      nodes {
        ...BlockContent
      }
    }
  }
`

export default Privacy
